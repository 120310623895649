import { KeycloakInstance } from 'keycloak-js';
import { configureStore, getDefaultMiddleware, Store } from '@reduxjs/toolkit';
import createSagaMiddleware, { SagaMiddleware } from 'redux-saga';
import { rootReducer, rootSaga } from 'stores';

/** *************> store.service.ts
 * Service Methods for Redux Store
 *
 * HOW TO USE :
 * - Calling the service :
 * import storeService, { StoreServiceInterface } from 'services/store.service';
 * const service : StoreServiceInterface = storeService();
 *
 * - Methods :
 * -- service.get() : Return the current Store context
 * -- service.init() : Initialize the Redux Store
 * -- service.run() : Run the Store and middlewares
 */

let sagaMiddleware: SagaMiddleware;
let store: Store;

const devMode = process.env.NODE_ENV === 'development';

// *> Store Service Interface
export interface StoreServiceInterface {
  get: () => Store;
  init: ( context: SagaContextInterface ) => void;
  run: () => void;
}

interface SagaContextInterface {
  keycloak?: KeycloakInstance;
}

/**
 * *> init()
 * Initialize the Redux Store, with Reducers and Middleware
 *
 * @param context : SagaContextInterface
 */
const init = ( context: SagaContextInterface ): void => {
  sagaMiddleware = createSagaMiddleware();
  const middleware = getDefaultMiddleware({ thunk: false }).concat(sagaMiddleware);
  store = configureStore({
    reducer: rootReducer,
    devTools: devMode,
    middleware,
  });
  sagaMiddleware.setContext( context );
};

/**
 * *> run()
 * Run the Redux Store, launching Saga middleware
 */
const run = (): void => {
  sagaMiddleware.run( rootSaga );
};

/**
 * *> get()
 * Get current Store Context
 *
 * @returns Store
 */
const get = (): Store => store;

export default (): StoreServiceInterface => {
  return { get, init, run };
};

