import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { updateCookie } from 'utils/cookies/cookies.helper';
import { CookieKey } from 'utils/cookies/cookies.config';

/** *************> defaultSlice.ts
 * Provide Redux actions and reducer for default stored elements
 *
 * STATE :
 * - themeDark: Boolean. Define if the dark theme is enabled
 * - language: String. Current language enabled (can be 'fr' or 'en')
 * - alerts: String[]. Array of alert keys to display in the App
 *
 * ACTIONS :
 * - toggleTheme() : Switch the themeDark State
 * - choiceLanguage() : Setter for language State
 * - addAlert() : Add one alert by key to the current alerts State
 * - removeAlert() : Remove one alert by index to the current alerts State
 */

const language = navigator.language && navigator.language.split(/[-_]/)[0] === 'fr' ? 'fr' : 'en';

const initialState = {
  themeDark: true,
  language,
  alerts: [],
};

export interface DefaultStateType {
  themeDark: boolean;
  language: string;
  alerts: string[];
}

// *> Default Slice
// See https://redux-toolkit.js.org/api/createSlice
const defaultSlice = createSlice({
  name: 'default',
  initialState,
  reducers: {
    // *> toggleTheme action
    toggleTheme: {
      reducer: (state: DefaultStateType): void => {
        state.themeDark = !state.themeDark;
        updateCookie(CookieKey.THEME, state.themeDark ? 'light' :  'dark');
      },
      prepare: (): { payload: unknown } => {
        return { payload: null };
      }
    },
    // *> choiceLanguage action
    choiceLanguage: {
      reducer: (state: DefaultStateType, action: PayloadAction<string>): void => {
        state.language = action.payload;
        updateCookie(CookieKey.LANG, state.language);
      },
      prepare: (lang: string): { payload: string } => {
        return { payload : lang };
      }
    },
    // *> addAlert action
    addAlert: {
      reducer: (state: DefaultStateType, action: PayloadAction<string>): void => {
        const newAlert = action.payload;
        state.alerts =[ ...state.alerts, newAlert];
      },
      prepare: (alertKeyToAdd: string): { payload: string } => {
        return { payload : alertKeyToAdd };
      }
    },
    // *> removeAlert action
    removeAlert: {
      reducer: (state: DefaultStateType, action: PayloadAction<number>): void => {
        const newAlerts = [...state.alerts];
        newAlerts.splice(action.payload, 1);
        state.alerts = newAlerts;
      },
      prepare: (alertIndexToRemove: number): { payload: number } => {
        return { payload : alertIndexToRemove };
      }
    },
  },
});

export const { toggleTheme, choiceLanguage, removeAlert, addAlert } = defaultSlice.actions;

export default defaultSlice.reducer;
