import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory, Link } from 'react-router-dom';
import { addTimeout } from 'utils/function/addTimeout';
import error_image from '../../assets/img/404_illustration.png';


/** *************> NotFound.tsx
 * Not Found page components
 */

/**
 * *> NotFound component
 * Display "Page Not Found"
 */
const NotFound: FC = (): JSX.Element => {
  const history = useHistory();
  addTimeout();
  return (
    <>
      <div className="alfw-error-body">
        <amds-text font="headline-h3" color="basic-white">
          Oups !
        </amds-text>
        <amds-text font="body-body3" color="basic-white">
          <FormattedMessage id="notfound.message" />
        </amds-text>
        <div className="amiltone-design-error-custom-size">
          <div className="d-flex justify-content-center">
            <amds-image src={error_image} shape="square" size="full" type="Default"/>
          </div>
        </div>
        <div className="container pt-3">
          <amds-button
            size = "medium"
            shape = "filled"
            color = "basic-white"
            elevation = "elevation0"
            onClick = {
              (): any => {
                history.push('/?agence=1');
              }
            }
          >
            <amds-text font="button-large" color="primary-900">
              <FormattedMessage id="notfound.retry" />
            </amds-text>
          </amds-button>
        </div>
        <div className="container pt-3">
          <Link target = '_blank' to = {{
            pathname: 'mailto:rgpd@amiltone.com' }
          }>
            <amds-button size="medium" shape="outline" color="basic-white" elevation="elevation0">
              <amds-icon name="ri-mail-line" size="24" color="basic-white"/>
              <amds-text font="button-large" color="basic-white">
                <FormattedMessage id="notfound.contact" />
              </amds-text>
            </amds-button>
          </Link>
        </div>
      </div>
    </>
  );
};

export default NotFound;
