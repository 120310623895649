import Cookies from 'universal-cookie';
import { Store } from 'redux';
import { CookieConfigInterface, CookieKey, cookiesConfig } from './cookies.config';

/** *************> cookies.helper.ts
 * Helper Methods for Cookies
 */

/**
 * *> updateStoreFromCookies()
 * Method to update the Redux Store with all Cookies
 * Load the configuration from utils/cookies/cookies.helper.ts
 *
 * @param store : Store
 */
export const updateStoreFromCookies = (store: Store): void => {
  const cookiesInstance = new Cookies();

  for(const config of Object.values(cookiesConfig)) {
    if(config.updateStore) {
      const value = cookiesInstance.get(config.name);
      config.updateStore(store, value);
    }
  }
};

/**
 * *> updateCookie()
 * Method to update one specific Cookie with value
 *
 * @param name : CookieKey for the Cookie (related to cookies.config.ts)
 * @param value : any
 */
export const updateCookie = (name: CookieKey, value: unknown): void => {
  const config: CookieConfigInterface = cookiesConfig[name];

  if(config) {
    const cookiesInstance = new Cookies();
    const updateFunc = config.updateCookie;
    if(updateFunc) {
      updateFunc(cookiesInstance, value);
    } else {
      // Default method
      if(value) {
        cookiesInstance.set(config.name, value, config.options);
      }
    }
  }

};
