import { updateStoreFromCookies } from '../utils/cookies/cookies.helper';
import keycloakService, { KeycloakServiceInterface } from './keycloak.service';
import storeService, { StoreServiceInterface } from './store.service';
import axiosService, { axiosConfig } from './axios';
import { defaultInterceptor, keycloakInterceptor } from './axios/interceptors';
import { AxiosServiceInterface } from './axios/axios.service';

/** *************> serviceRunner.ts
 * Methods to load all Services for the App
 */

// *> Services Interface
// Provide all services to load
export interface ServicesInterface {
  axios: AxiosServiceInterface;
  keycloak?: KeycloakServiceInterface;
  store: StoreServiceInterface;
}

/**
 * *> runServices()
 * Main method to run and return all services
 *
 * @returns ServicesInterface
 */
export const runServices = (): ServicesInterface => {
  // *> Create all services
  let keycloak;
  if(process.env.REACT_APP_AUTH_TYPE === 'keycloak') {
    keycloak = keycloakService();
  }
  const services = {
    axios : axiosService(),
    keycloak,
    store: storeService()
  };

  // *> Initialize and run Services
  // Axios
  services.axios.init('default', defaultInterceptor, axiosConfig.defaultConfig);
  if (keycloak) {
    services.axios.init('keycloak', keycloakInterceptor, axiosConfig.keycloakConfig, [keycloak.get()]);
  }

  // Store
  if(keycloak) {
    services.store.init({ keycloak : keycloak.get() });
  } else {
    services.store.init({});
  }
  services.store.run();
  updateStoreFromCookies(services.store.get());

  return services;
};


