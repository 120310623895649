import { createBrowserHistory } from 'history';
import React, { FC, Suspense } from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import { DefaultLayout } from 'containers/layouts';
import Routes from 'routes.config';

/** *************> Router.tsx
 * High Order Component for rendering routes and Content into the App
 */

export const history = createBrowserHistory({
  basename: process.env.PUBLIC_URL
});

export type History = typeof history;

interface RouteInterface {
  path?: string;
  key: string;
  component: React.ComponentType | (() => Promise<any>);
  layout?: FC<any> | React.ComponentClass;
  exact?: boolean;
  lazyloaded?: boolean;
}

/**
 * *> AppRouter component
 * Rendering routes from routes.config.tsx
 */
const AppRouter: FC = (): JSX.Element => {
  return (
    <Router history={history} >
      <Suspense fallback={<div>Chargement...</div>}>
        <Switch>
          {Routes.map((route: RouteInterface ): JSX.Element => {
            const routeArg: any = { ...route };

            delete routeArg.key;
            delete routeArg.component;
            delete routeArg.layout;
            delete routeArg.lazyloaded;

            const Layout = route.layout || DefaultLayout;

            let Page: React.LazyExoticComponent<any> | React.ComponentType;
            if (route.lazyloaded) {
              Page = React.lazy(route.component as () => Promise<any>);
            } else {
              Page = route.component as React.ComponentType;
            }

            return (
              <Route key={route.key} {...routeArg} >
                <Layout >
                  <Page/>
                </Layout>
              </Route>
            );
          })}
        </Switch>
      </Suspense>
    </Router>
  );

};

export default AppRouter;
