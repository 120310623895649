import { createSlice, PayloadAction } from '@reduxjs/toolkit';

/** *************> authSlice.ts
 * Provide Redux actions and reducer for authentication data
 *
 * STATE :
 * - isAuthenticated: Boolean. Define if the user is authenticated to the App
 *
 * ACTIONS :
 * - setAuthenticated() : Setter for isAuthenticated State
 */

const initialState = {
  isAuthenticated: false,
};

export type AuthStateType = typeof initialState;

// *> Slice for Auth
// See https://redux-toolkit.js.org/api/createSlice
const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    // *> setAuthenticated action
    setAuthenticated: {
      reducer: (state: AuthStateType, action: PayloadAction<boolean>): void => {
        state.isAuthenticated = action.payload;
      },
      prepare: (isAuth: boolean): { payload: boolean } => {
        return { payload : isAuth };
      }
    },
  },
});

export const { setAuthenticated } = authSlice.actions;

export default authSlice.reducer;
