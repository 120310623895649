import React, { FC, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { setValue } from 'stores/loader';
import { setVisitReason, setOtherVisitReason } from 'stores/parcours';
import { Formik, Field, Form, FormikHelpers } from 'formik';
import { addTimeout } from 'utils/function/addTimeout';
import * as Yup from 'yup';
import blue_bottom_right from '../../assets/img/amiltone_design_blue_bottom_right.png';
interface Values {
  other_visit_reason: string;
}

const motifSchema = Yup.object().shape({
  other_visit_reason: Yup.string()
    .min(2, 'Too Short!')
    .max(255, 'Too Long!')
    .required('Required'),
});

export const Motif: FC = (): JSX.Element => {
  const history = useHistory();
  const dispatch = useDispatch();

  addTimeout();
  useEffect(() => {
    dispatch(setValue(60));
  }, []);

  return (
    <>
      <div className="container pt-3">
        <amds-text font="headline-h4" color="primary-600">
          Quel est le motif de votre visite ?
        </amds-text>
        <div className="row">
          <div className="col-6">
            <div
              id="reunion"
              className="amds-card-custom"
              onClick={(): any => {
                dispatch(setVisitReason(1));
                history.push('/visite');
              }}
            >
              <div className="card-icon" color="primary-100">
                <amds-icon
                  name="ri-team-fill"
                  size="16x16"
                  color="primary-500"
                />
              </div>
              <amds-text font="subtitle-subtitle1" color="primary-500">
                <FormattedMessage id="motif.meeting" />
              </amds-text>
            </div>
            <div
              id="visite"
              className="amds-card-custom"
              onClick={(): any => {
                dispatch(setVisitReason(3));
                history.push('/visite');
              }}
            >
              <div className="card-icon" color="primary-100">
                <amds-icon
                  name="ri-eye-2-line"
                  size="16x16"
                  color="primary-500"
                />
              </div>
              <amds-text font="subtitle-subtitle1" color="primary-500">
                <FormattedMessage id="motif.visit" />
              </amds-text>
            </div>
          </div>
          <div className="col-6">
            <div
              id="entretien"
              className="amds-card-custom"
              onClick={(): any => {
                dispatch(setVisitReason(2));
                history.push('/visite');
              }}
            >
              <div className="card-icon" color="primary-100">
                <amds-icon
                  name="ri-file-user-line"
                  size="16x16"
                  color="primary-500"
                />
              </div>
              <amds-text font="subtitle-subtitle1" color="primary-500">
                <FormattedMessage id="motif.interview" />
              </amds-text>
            </div>
            <div
              id="intervention"
              className="amds-card-custom"
              onClick={(): any => {
                dispatch(setVisitReason(4));
                history.push('/visite');
              }}
            >
              <div className="card-icon" color="primary-100">
                <amds-icon
                  name="ri-briefcase-2-line"
                  size="16x16"
                  color="primary-500"
                />
              </div>
              <amds-text font="subtitle-subtitle1" color="primary-500">
                <FormattedMessage id="motif.intervention" />
              </amds-text>
            </div>
          </div>
        </div>
        <Formik
          initialValues={{
            other_visit_reason: '',
          }}
          validationSchema={motifSchema}
          onSubmit={(
            values: Values,
            { setSubmitting }: FormikHelpers<Values>
          ): void => {
            setTimeout(() => {
              dispatch(setVisitReason(6));
              dispatch(setOtherVisitReason(values.other_visit_reason));
              setSubmitting(false);
              history.push('/visite');
            }, 500);
          }}
        >
          {({ isSubmitting, errors, touched }: any): any => (
            <Form>
              <div className="d-block form-text">
                <amds-form-element>
                  <amds-input shape="filled" color="primary-500" >
                    <Field
                      id="other_visit_reason"
                      name="other_visit_reason"
                      placeholder="Autre, merci de spécifier"
                      className="amds-input-element amds-input-custom-size amds-font-body-body1"
                      type="text"
                    />
                  </amds-input>
                </amds-form-element>
              </div>
              {errors.other_visit_reason && touched.other_visit_reason ? (
                <div className="error ">
                  <div className="d-inline">
                    <amds-icon className="d-inline" name="warning" size="16" color="warning-500"/>
                  </div>
                  <div className="d-inline pl-2">
                    <amds-text font="other-caption" color="warning-500">
                      {errors.other_visit_reason}
                    </amds-text>
                  </div>
                </div>
              ) : null}
              <amds-button type="submit" disabled={isSubmitting} size="medium" shape="filled" color="primary-500" >
                <amds-text font="button-large"color="basic-white">
                  Suivant
                </amds-text>
              </amds-button>
            </Form>
          )}
        </Formik>
      </div>
      <div className="amds-image-fixed-bottom-right">
        <amds-image
          src={blue_bottom_right}
          shape="square"
          size="full"
          type="Default"
        />
      </div>
    </>
  );
};
