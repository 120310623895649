/** *************> rootSaga.ts
 * Standard Redux Saga file to aggregate multiple Saga in one export
 * Using it to run the Saga middleware in services/store.service.ts
 *
 * See https://redux-saga.js.org/docs/advanced/RootSaga.html
 */

// *> Root Saga
// Add any custom saga file to use it into Redux Saga
export default function* rootSaga(): Generator {
}
