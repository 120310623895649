import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setOffice } from 'stores/parcours';
import AlfredDataInterfaceService from '../../services/alfred.service';
import image from '../../assets/img/amiltone_design_pink.png';

/** *************> Home.tsx
 * Home components
 */

/**
 * *> Home component
 * Display Starter Kit Home page
 */
const Home: FC = (): JSX.Element => {

  const history = useHistory();
  const dispatch = useDispatch();

  // Récupérer l'id agence
  const queryParams = new URLSearchParams(window.location.search);
  const agence = queryParams.get('agence') as unknown | number;

  AlfredDataInterfaceService.getOffices()
    .then((response: any) => {
      let isOfficeOk = false;
      for (const data of response.data.data) {
        if( Number(data.id) === Number(agence) ) {
          isOfficeOk = true;
          break;
        }
      }
      if(!isOfficeOk) {
        history.push('/404');
      } else {
        dispatch(setOffice(Number(agence)));
      }
    })
    .catch((e: any) => {
      console.log(e);
    });

  return (
    <>
      <div className="amiltone-design-custom-size">
        <amds-image className="float-left" src={image} shape="square" size="full" type="Default" />
      </div>
      <div className="alfw-accueil-body">
        <amds-text font="headline-h6" color="primary-500">
          <FormattedMessage id="home.title" /><span className="titledot">.</span>
        </amds-text>
        <amds-text font="body-body3" color="primary-500">
          <FormattedMessage id="home.presentation"/>
        </amds-text>
        <amds-button
          size = "medium"
          shape = "filled"
          color = "primary-600"
          elevation = "elevation0"
          onClick = {
            (): any => {
              history.push('/rgpd');
            }
          }>
          <amds-text font="button-large" color="basic-white">
            <FormattedMessage id="home.link" />
          </amds-text>
        </amds-button>
      </div>
    </>
  );
};

export default Home;
