import {
  FormLayout,
  EmptyLayout,
  AmiltoneLayout,
  AmiltoneErrorLayout
} from 'containers/layouts';
import {
  Home,
  NotFound,
  Rgpd,
  Confirmation,
  Parcours,
  Entreprise,
  Particulier,
  Motif,
  Visite,
} from 'pages';

/** *************> routes.config.tsx
 * Routes configuration for the React App
 * Available parameters :
 * - path: string. Required. Path for the route
 * - key : string. Required. Key name for the route
 * - component : React Component. Required. Component to render into the route (inside the layout)
 * - layout : React Component. Layout Component to render into the route (Default otherwise)
 * - lazyloaded : Boolean. If the route must be lazy loaded
 *
 * Moreover, all parameters from react-router-dom <Route> component can be used
 * See https://reactrouter.com/web/api/Route
 */
export default [
  {
    path: '/',
    key: 'HOME',
    component: Home,
    exact: true,
    layout: AmiltoneLayout,
  },
  {
    path: '/rgpd',
    key: 'RGPD',
    component: Rgpd,
    layout: EmptyLayout
  },
  {
    path: '/parcours',
    key: 'PARCOURS',
    component: Parcours,
    layout: FormLayout
  },
  {
    path: '/entreprise',
    key: 'ENTREPRISE',
    component: Entreprise,
    layout: FormLayout
  },
  {
    path: '/particulier',
    key: 'PARTICULIER',
    component: Particulier,
    layout: FormLayout
  },
  {
    path: '/motif',
    key: 'MOTIF',
    component: Motif,
    layout: FormLayout
  },
  {
    path: '/visite',
    key: 'VISITE',
    component: Visite,
    layout: FormLayout
  },
  {
    path: '/confirmation',
    key: 'CONFIRMATION',
    component: Confirmation,
    layout: AmiltoneLayout,
  },
  {
    key: 'NOT_FOUND',
    component: NotFound,
    layout: AmiltoneErrorLayout,
  }
];
