import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { addTimeout } from 'utils/function/addTimeout';
import rgpd from '../../assets/img/rgpd.png';

/** *************> Home.tsx
 * Home components
 */

/**
 * *> Home component
 * Display Starter Kit Home page
 */
const Rgpd: FC = (): JSX.Element => {
  addTimeout();
  const history = useHistory();
  return (
    <>
      <p>
        <amds-text font="body-body3">
          Les données collectées seront communiquées aux seuls destinataires
          suivants : service informatique et responsable de sécurité.
        </amds-text>
      </p>
      <p><amds-text font="body-body3">Les données sont conservées pendant trois années civiles.</amds-text></p>
      <p>
        <amds-text font="body-body3">
          Vous pouvez accéder aux données vous concernant, les rectifier, demander
          leur effacement ou exercer votre droit à la limitation du traitement de
          vos données. Vous pouvez également exercer votre droit à la portabilité de
          vos données.
        </amds-text>
      </p>
      <p>
        <amds-text font="body-body3">
          <span>Consultez le site</span>
          <a className="react-link font-weight-bold"
            href="https://www.cnil.fr/"
            target="_blank"
            rel="noopener noreferrer"> cnil.fr </a>
          <span>pour plus d’informations sur vos droits.</span>
        </amds-text>
      </p>
      <p>
        <amds-text font="body-body3">
          <span>
          Pour exercer ces droits ou pour toute question sur le traitement de vos
          données dans ce dispositif, vous pouvez contacter le DPO (Data Protection
          Officer) désigné par Amiltone à adresse suivante :
          </span>
        </amds-text>
      </p>
      <div>
        <div className="d-flex justify-content-center">
          <amds-image src={rgpd} shape="square" size="full" type="Default"/>
        </div>
      </div>
      <p>
        <amds-text font="body-body3">
          Si vous estimez, après nous avoir contactés, que vos droits « Informatique
          et Libertés » ne sont pas respectés, vous pouvez adresser une réclamation
          à la CNIL.
        </amds-text>
      </p>
      <amds-button size="medium"
        shape="filled"
        color="primary-600"
        elevation="elevation0"
        onClick={
          (): any => {
            history.push('/parcours');
          }
        }>
        <amds-text font="button-large" color="basic-white">
          <FormattedMessage id="rgpd.link" />
        </amds-text>
      </amds-button>
    </>
  );
};

export default Rgpd;
