import React, { FC, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setValue } from 'stores/loader';
import { setFirstName, setLastName, setCompanyName } from 'stores/parcours';
import { Formik, Field, Form, FormikHelpers } from 'formik';
import { addTimeout } from 'utils/function/addTimeout';
import * as Yup from 'yup';
import blue_bottom_left from '../../assets/img/amiltone_design_blue_bottom_left.png';

interface Values {
  firstName: string;
  lastName: string;
  companyName: string;
}

const particulierSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, 'Too Short!')
    .max(255, 'Too Long!')
    .required('Required'),
  lastName: Yup.string()
    .min(2, 'Too Short!')
    .max(255, 'Too Long!')
    .required('Required'),
  companyName: Yup.string()
    .min(2, 'Too Short!')
    .max(255, 'Too Long!')
    .required('Required'),
});

export const Entreprise: FC = (): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();

  addTimeout();
  useEffect(() => {
    dispatch(setValue(40));
  }, []);
  return (
    <>
      <div className="container pt-3">
        <amds-text font="headline-h4" color="primary-600">Qui êtes-vous ?</amds-text>
        <Formik
          initialValues={{
            firstName: '',
            lastName: '',
            companyName: '',
          }}
          validationSchema={particulierSchema}
          onSubmit={(
            values: Values,
            { setSubmitting }: FormikHelpers<Values>
          ): void => {
            setTimeout(() => {
              dispatch(setFirstName(values.firstName));
              dispatch(setLastName(values.lastName));
              dispatch(setCompanyName(values.companyName));
              setSubmitting(false);
              history.push('/motif');
            }, 500);
          }}
        >
          {({ isSubmitting, errors, touched }: any): any => (
            <Form>
              <div className="d-block form-text pb-3">
                <amds-form-element>
                  <label htmlFor="firstName">
                    <amds-text font="body-body1" color="primary-700">Prénom *</amds-text>
                  </label>
                  <amds-input shape="filled" color="primary-500" >
                    <Field
                      id="firstName"
                      name="firstName"
                      placeholder="Martine"
                      className="amds-input-element amds-input-custom-size amds-font-body-body1"
                      type="text"
                    />
                  </amds-input>
                </amds-form-element>
              </div>
              {errors.firstName && touched.firstName ? (
                <div className="error ">
                  <div className="d-inline">
                    <amds-icon className="d-inline" name="warning" size="16" color="warning-500"/>
                  </div>
                  <div className="d-inline pl-2">
                    <amds-text font="other-caption" color="warning-500">
                      {errors.firstName}
                    </amds-text>
                  </div>
                </div>
              ) : null}
              <div className="d-block form-text pb-3">
                <amds-form-element>
                  <label htmlFor="lastName"><amds-text font="body-body1" color="primary-700">Nom *</amds-text></label>
                  <amds-input shape="filled" color="primary-500" >
                    <Field
                      id="lastName"
                      name="lastName"
                      placeholder="Dupond"
                      className="amds-input-element amds-input-custom-size amds-font-body-body1"
                      type="text"
                    />
                  </amds-input>
                </amds-form-element>
                {errors.lastName && touched.lastName ? (
                  <div className="error ">
                    <div className="d-inline">
                      <amds-icon className="d-inline" name="warning" size="16" color="warning-500"/>
                    </div>
                    <div className="d-inline pl-2">
                      <amds-text font="other-caption" color="warning-500">
                        {errors.lastName}
                      </amds-text>
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="d-block form-text pb-3">
                <amds-form-element>
                  <label htmlFor="companyName">
                    <amds-text font="body-body1" color="primary-700">Nom de votre entreprise *</amds-text>
                  </label>
                  <amds-input shape="filled"color="primary-500" >
                    <Field
                      id="companyName"
                      name="companyName"
                      placeholder="Amiltone"
                      className="amds-input-element amds-input-custom-size amds-font-body-body1"
                      type="text" />
                  </amds-input>
                </amds-form-element>
                {errors.companyName && touched.companyName ? (
                  <div className="error ">
                    <div className="d-inline">
                      <amds-icon className="d-inline" name="warning" size="16" color="warning-500"/>
                    </div>
                    <div className="d-inline pl-2">
                      <amds-text font="other-caption" color="warning-500">
                        {errors.companyName}
                      </amds-text>
                    </div>
                  </div>
                ) : null}
              </div>
              <amds-button type="submit" disabled={isSubmitting} size="medium" shape="filled" color="primary-500" >
                <amds-text font="button-large"color="basic-white">
                  Suivant
                </amds-text>
              </amds-button>
            </Form>
          )}
        </Formik>
      </div>
      <div className="amds-image-fixed-bottom">
        <amds-image src={blue_bottom_left} shape="square" size="full" type="Default"/>
      </div>
    </>
  );
};
