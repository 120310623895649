import React, { FC } from 'react';
import { Container } from 'components/bootstrap';

/** *************> EmptyLayout.tsx
 * Empty Layout Components
 *
 * Layout component are used as a wrapper for the page,
 * Specific page layout can be set into src/routes.config.tsx
 */

// *> Empty Layout Props Interface
export interface EmptyLayoutProps {
  children: React.ReactNode;
}

/**
 * *> EmptyLayout component
 * Simple render layout
 */
const EmptyLayout: FC<EmptyLayoutProps> = ({ children }: EmptyLayoutProps): JSX.Element => (
  <Container fluid className="main justify-content-center align-items-center text-center pt-5">
    { children }
  </Container>
);
export default EmptyLayout;
