import ErrorInterface from './error.interface';

/** *************> defaultError.ts
 * Standard error Class to throw when any error occurs
 */
export default class DefaultError extends Error implements ErrorInterface {
  // Error Name
  public name: string;
  // Error unique Key
  public key: string;
  // Error extra data
  public error: any;

  public constructor(
    name: string,
    key: string,
    message: string,
    error?: unknown,
  ){
    super(message);
    this.name = name;
    this.key = key;
    this.error = error ? error : '';
  }
}
