import { combineReducers } from '@reduxjs/toolkit';
import Auth, { AuthStateType } from 'stores/auth';
import Default, { DefaultStateType } from 'stores/default';
import Loader, { LoaderStateType } from 'stores/loader';
import AlfredDataInterface from 'types/alfred.type';
import Parcours from './parcours';

/** *************> rootReducer.ts
 * Standard Redux file to combine and use all reducers
 * Using it to create the Redux store in services/store.service.ts
 *
 * See https://redux.js.org/api/combinereducers
 */

// *> Combined reducers
// Add any custom reducer to use it into the Redux Store
const rootReducer =  combineReducers({
  Default,
  Auth,
  Loader,
  Parcours
});

// *> RootReducer Type
export interface RootReducerType {
  Default: DefaultStateType;
  Auth: AuthStateType;
  Loader: LoaderStateType;
  Parcours: AlfredDataInterface;
}

export default rootReducer;
