
import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootReducerType } from 'stores';
import { removeAlert } from 'stores/default';
import { Alert } from 'components/bootstrap';
import { FormattedMessage } from 'react-intl';

/** *************> AlertCenter.tsx
 * Alert Center components
 */

// *> Alert Center Props Interface
interface AlertCenterProps {
  className?: string;
}

/**
 * *> AlertCenter component
 * Display current Redux Store errors
 *
 * PROPS :
 * - className : String. Specific class to apply to the parent div
 */
const AlertCenter: FC<AlertCenterProps > = ( { className = '' }: AlertCenterProps ): JSX.Element => {
  const dispatch = useDispatch();
  const alerts = useSelector(({ Default }: RootReducerType): string[] => Default.alerts);

  return (
    <div className={`position-fixed fixed-bottom w-33 left-auto ${className}`} >
      {alerts.map((errorKey: string, index: number) => (
        <Alert
          key={`${errorKey}_${index}`}
          variant="danger"
          className='rounded mr-1 mb-1 '
          dismissible
          onClose={ (): void => {
            dispatch(removeAlert(index));
          } }>
          <small className='m-0 ' >
            <FormattedMessage id={`errors.${errorKey}`} />
          </small>
        </Alert>
      ))}

    </div>
  );
};

export default AlertCenter;
