import { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { KeycloakInstance } from 'keycloak-js';
import storeService from 'services/store.service';
import { setAuthenticated } from 'stores/auth';
import { AXIOS_INTERCEPTOR_KEYCLOAK_ERROR, defaultError, handleErrors } from 'utils/errors';

/** *************> keycloakInterceptor.ts
 * An interceptor is an extra process added to an Axios Instance
 * Keycloak Interceptor adds Authorization Header and Token handler
 */

interface RequestHeaders {
  Authorization?: string;
}

/**
 * *> keycloakInterceptor()
 * Function Interceptor for Keycloak
 *
 * @param instance : AxiosInstance
 * @param keycloak : KeycloakInstance
 */
export const keycloakInterceptor = (
  instance: AxiosInstance,
  keycloak: KeycloakInstance
): void => {

  // *> Define interceptor for the request
  instance.interceptors.request.use(async (requestConfig: AxiosRequestConfig) => {
    const { token, refreshToken } = keycloak;

    // *> Refresh Token if needed
    if (refreshToken) {
      try {
        await keycloak.updateToken(1000);
      } catch (error) {
        handleErrors(
          new defaultError(
            AXIOS_INTERCEPTOR_KEYCLOAK_ERROR.errorName,
            AXIOS_INTERCEPTOR_KEYCLOAK_ERROR.errorKey,
            AXIOS_INTERCEPTOR_KEYCLOAK_ERROR.errorMessage,
          )
        );
        return requestConfig;
      }
    }

    // *> Add Authorization Header
    const headers = requestConfig.headers as RequestHeaders;
    headers.Authorization = token ? `Bearer ${token}` : '';
    return requestConfig;
  });

  // *> Define interceptor for the response
  instance.interceptors.response.use((response: AxiosResponse): AxiosResponse => {
    return response;
  }, (error: unknown): Promise<never> => {

    const errorResult = error as AxiosError;

    if (401 === errorResult?.response?.status) {
      keycloak.clearToken();
      storeService().get().dispatch(setAuthenticated(false));
    }
    return Promise.reject(errorResult);
  });
};
