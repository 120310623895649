import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';


/** *************> HeaderForm.tsx
 * HeaderForm components
 */

/**
 * *> HeaderForm component
 * Display Starter Kit HeaderForm
 */
const HeaderForm: FC = (): JSX.Element => {
  const history = useHistory();
  const loaderValue: number = useSelector(((state: any) => state.Loader.value ));
  return (
    <div className="container pt-3">
      <div className="position-absolute top-0 start-0">
        <amds-button
          size = "medium"
          shape = "ghost"
          color = "primary-600"
          elevation = "elevation0"
          onClick = {
            (): any => {
              history.goBack();
            }
          }>
          <amds-icon name="arrow_back" size="18" color="primary-600"/>
        </amds-button>
      </div>
      <div className="d-flex justify-content-center">
        <amds-image src="logo-Amiltone.png" shape="square" size="24x24" type="Default"/>
      </div>
      <div className="container pt-4">
        <amds-loader size="large" type="linear" value={ loaderValue } color="third-500"/>
      </div>
    </div>
  );
};

export default HeaderForm;
