import http from '../api/api-alfred';
import AlfredDataInterface from '../types/alfred.type';

class AlfredDataInterfaceService {
  public async getVisitors(): Promise<any> {
    await http.get('/visitors');
    return;
  }

  public async getOffices(): Promise<any> {
    const offices = await http.get('/offices');
    return offices;
  }

  public async updateVisitor(data: AlfredDataInterface): Promise<any> {
    const updateVisitor = await http.patch('/visitors', data);
    return updateVisitor;
  }

  public async deleteVisitor(id: number): Promise<any> {
    const deleteVisitor = await http.delete(`/visitors/${id}`);
    return deleteVisitor;
  }

  public async deleteVisitors(ids: number): Promise<any> {
    const deleteVisitors = await http.delete(`/visitors/${ids}`);
    return deleteVisitors;
  }

  public async getAllCollaborators(): Promise<any> {
    const allCollaborators = await http.get('/collaborators');
    return allCollaborators;
  }

  public async getCollaboratorsByName(name: string): Promise<any> {
    const collaboratorsByName = await http.get(`/collaborators?search=${name}&limit=4`);
    return collaboratorsByName;
  }

  public async getCollaboratorsByCompleteName(
    first_part: string,
    second_part: string
  ): Promise<any> {
    const collaboratorsByCompleteName = await http.get(
      `/collaborators?filter={"_or":[{"_and":[{"last_name":{"_starts_with":"${first_part}"}},
      {"first_name":{"_starts_with":"${second_part}"}}]},
      {"_and":[{"first_name":{"_starts_with":"${first_part}"}},{"last_name":{"_starts_with":"${second_part}"}}]}]}
      &limit=4`
    );
    return collaboratorsByCompleteName;
  }

  public async create(data: AlfredDataInterface): Promise<any> {
    const create = await http.post('/visitors', data);
    return create;
  }
}

export default new AlfredDataInterfaceService();
