import keycloak, { KeycloakConfig, KeycloakInitOptions, KeycloakInstance } from 'keycloak-js';
import { setAuthenticated } from 'stores/auth';
import { KEYCLOAK_INIT_ERROR, defaultError, handleErrors } from 'utils/errors';
import storeService from './store.service';

/** *************> keycloak.service.ts
 * Service Methods for Keycloak (Authentication service)
 *
 * HOW TO USE :
 * - Calling the service :
 * import keycloakService, { KeycloakServiceInterface } from 'services/keycloak.service';
 * const service : KeycloakServiceInterface = keycloakService();
 *
 * - Methods :
 * -- service.get() : Return the current KeycloakInstance
 * -- service.open() : Open connection page to Keycloak
 */

const realm = process.env.REACT_APP_KEYCLOAK_REALM;
const clientId = process.env.REACT_APP_KEYCLOAK_CLIENT_ID;

const keycloakConfig: KeycloakConfig = {
  url: process.env.REACT_APP_KEYCLOAK_URL,
  realm: realm ? realm : '',
  clientId: clientId ? clientId : '',
};

// *> KeycloakService Interface
export interface KeycloakServiceInterface {
  get: () => KeycloakInstance;
  open: (initOptions: KeycloakInitOptions) => Promise<void>;
}

// *> KeycloakInstance initializer
// Using keycloak-js package
const keycloakInstance = keycloak(keycloakConfig);

/**
 * *> get()
 * Get current KeycloakInstance
 *
 * @returns KeycloakInstance
 */
const get = (): KeycloakInstance => keycloakInstance;

/**
 * *> open()
 * Initialize and open Keycloak authentication
 *
 * @param initOptions : KeycloakInitOptions
 * @returns Promise
 */
const open = async (initOptions: KeycloakInitOptions): Promise<void> => {
  try {
    const isAuthenticated = await keycloakInstance.init(initOptions);
    storeService().get().dispatch(setAuthenticated(isAuthenticated));
  } catch (error) {
    handleErrors(
      new defaultError(
        KEYCLOAK_INIT_ERROR.errorName,
        KEYCLOAK_INIT_ERROR.errorKey,
        KEYCLOAK_INIT_ERROR.errorMessage,
      )
    );
  }
};

export default (): KeycloakServiceInterface => {
  return { get, open };
};


