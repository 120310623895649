import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Define a type for the slice state

// Define the initial state using that type
const initialState = {
  value: 0
};

export interface LoaderStateType {
  value: number;
}

export const loaderSlice = createSlice({
  name: 'loader',
  initialState,
  reducers: {
    setValue: {
      reducer: (state: LoaderStateType, action: PayloadAction<number>): void => {
        state.value = action.payload;
      },
      prepare: (value: number): { payload: number } => {
        return { payload : value };
      }
    }
  }
});


export const { setValue } = loaderSlice.actions;

export default loaderSlice.reducer;
