import React, { FC, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setValue } from 'stores/loader';
import {
  setVisitedPerson,
  setDateCreated,
  setOtherVisitedPerson,
} from 'stores/parcours';
import { addTimeout } from 'utils/function/addTimeout';
import AlfredDataInterfaceService from '../../services/alfred.service';
import pink_bottom from '../../assets/img/amiltone_design_pink_bottom_v2.png';

const useParcoursForm: any = () => {
  const [inputs, setInputs]: any = useState({ name: '' });

  const handleInputChange: any = (event: any) => {
    event.persist();
    setInputs((handleInputs: any) => ({
      ...handleInputs,
      [event.target.name]: event.target.value,
    }));
  };

  return {
    handleInputChange,
    inputs,
  };
};

export const Visite: FC = (): JSX.Element => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { inputs, handleInputChange } = useParcoursForm();
  const [collaborators, setCollaborators]: any = useState([]);
  const [showButton, setShowButton]: any = useState(false);

  addTimeout();

  useEffect(() => {
    dispatch(setValue(80));
  }, []);

  useEffect((): any => {
    getCollaboratorsData();
  }, [inputs.name]);

  const toggleSelected = (id: string): void => {
    const collaboratorElements =
      document.getElementsByTagName('amds-list-element');
    const collaboratorElem = document.getElementById(id);
    const getSelected = collaboratorElem?.getAttribute('selected');

    Array.prototype.map.call(
      collaboratorElements,
      (collaboratorElement: HTMLElement): void =>
        collaboratorElement.setAttribute('selected', 'false')
    );

    Array.prototype.map.call(
      collaboratorElements,
      (collaboratorElement: HTMLElement): void =>
        collaboratorElement.children[0].children[2].children[0].setAttribute('color', 'primary-500')
    );

    Array.prototype.map.call(
      collaboratorElements,
      (collaboratorElement: HTMLElement): void =>
        collaboratorElement.children[0].children[2].children[1].setAttribute('color', 'basic-500')
    );

    if (getSelected === 'true' && collaboratorElem !== null) {
      collaboratorElem.setAttribute('selected', 'false');
      collaboratorElem.children[0].children[2].children[0].setAttribute('color', 'primary-500');
      collaboratorElem.children[0].children[2].children[1].setAttribute('color', 'basic-500');
      setShowButton(false);
    } else if (getSelected === 'false' && collaboratorElem !== null) {
      if (id === 'other_visited_person_name') {
        dispatch(setVisitedPerson(null));
        dispatch(setOtherVisitedPerson(inputs.name));
      } else {
        dispatch(setVisitedPerson(Number(id)));
      }
      collaboratorElem.setAttribute('selected', 'true');
      collaboratorElem.children[0].children[2].children[0].setAttribute('color', 'basic-white');
      collaboratorElem.children[0].children[2].children[1].setAttribute('color', 'basic-white');
      setShowButton(true);
    }
  };

  const getCollaboratorsData = (): void => {
    const splitName = inputs.name.split(' ');
    if (splitName.length > 1 && splitName[1] !== '') {
      const firstPart: string = splitName[0]
        .charAt(0)
        .toUpperCase()
        .concat(splitName[0].slice(1).toLowerCase());
      const secondPart: string = splitName[1]
        .charAt(0)
        .toUpperCase()
        .concat(splitName[1].slice(1).toLowerCase());

      AlfredDataInterfaceService.getCollaboratorsByCompleteName(
        firstPart,
        secondPart
      )
        .then((response: any) => {
          const collaboratorsData = response.data.data;
          setCollaborators(collaboratorsData);
        })
        .catch((e: any) => {
          console.log(e);
        });
    } else {
      if (inputs.name !== '') {
        AlfredDataInterfaceService.getCollaboratorsByName(splitName[0])
          .then((response: any) => {
            const collaboratorsData = response.data.data;
            setCollaborators(collaboratorsData);
          })
          .catch((e: any) => {
            console.log(e);
          });
      }
    }
  };

  return (
    <>
      <div className="container pt-3">
        <amds-text font="headline-h4" color="primary-600">
          À qui rendez-vous visite ?
        </amds-text>
        <div className="d-block form-text">
          <amds-form-element>
            <amds-input shape="filled" color="primary-500" >
              <input
                type="text"
                name="name"
                id="name"
                className='amds-input-element amds-input-custom-size amds-font-body-body1'
                placeholder="Prénom, Nom"
                onChange={handleInputChange}
                value={inputs.name}
                required
              />
            </amds-input>
          </amds-form-element>
        </div>
        <amds-list elevation="elevation0">
          {collaborators && (
            <div className="collaborators">
              {collaborators.map((collaborator: any) => (
                <amds-list-element
                  id={String(collaborator.id)}
                  key={collaborator.id}
                  color="basic-white"
                  selected={false}
                  onClick={(): any => {
                    toggleSelected(String(collaborator.id));
                  }}
                >
                  {collaborator.picture !== null ? (
                    <amds-image
                      type="Default"
                      shape="circle"
                      size="40x40"
                      src={`http://alfred-admin-testing.amiltone.com/assets/${
                        collaborator.picture as string
                      }?access_token=${process.env.REACT_APP_ALFRED_API_KEY as string}`}
                    />
                  ) : (
                    <amds-image
                      src=""
                      shape="circle"
                      size="40x40"
                      type="Upload"
                      color="basic-200"
                    >
                      <amds-icon
                        name="person"
                        className="amds-icon-align-middle"
                        size="24"
                        color="basic-500"
                      />
                    </amds-image>
                  )}
                  <div className="amds-text-group">
                    <amds-text font="body-body1" color="primary-500">
                      {collaborator.first_name} {collaborator.last_name}
                    </amds-text>
                    <amds-text font="body-body2" color="basic-500">
                      {collaborator.position_held}
                    </amds-text>
                  </div>
                </amds-list-element>
              ))}
            </div>
          )}
          <amds-list-element
            id="other_visited_person_name"
            color="basic-white"
            selected={false}
            onClick={(): any => {
              toggleSelected('other_visited_person_name');
            }}
          >
            <amds-image
              src=""
              shape="circle"
              size="40x40"
              type="Upload"
              color="basic-200"
            >
              <amds-icon
                name="person"
                className="amds-icon-align-middle"
                size="24"
                color="basic-500"
              />
            </amds-image>
            <div className="amds-text-group">
              <amds-text font="body-body1" color="primary-500">
                Autre collaborateur
              </amds-text>
              <amds-text font="body-body2" color="basic-500">
                Veuillez inscrire le prénom et le nom du collaborateur dans le champs de recherche
              </amds-text>
            </div>
          </amds-list-element>
        </amds-list>
        {showButton ? (
          <amds-button
            size="medium"
            shape="filled"
            color="primary-600"
            elevation="elevation0"
            onClick={(): any => {
              dispatch(setDateCreated(new Date().toString()));
              history.push('/confirmation');
            }}
          >
            Terminé
          </amds-button>
        ) : (
          <amds-button
            size="medium"
            shape="filled"
            color="primary-600"
            elevation="elevation0"
            disabled
          >
            Terminé
          </amds-button>
        )}
      </div>
      <div className="amds-image-fixed-bottom">
        <amds-image
          src={pink_bottom}
          shape="square"
          size="full"
          type="Default"
        />
      </div>
    </>
  );
};
