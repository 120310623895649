import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import AlfredDataInterface from 'types/alfred.type';

export function addTimeout(): void {
  const history = useHistory();
  const data: AlfredDataInterface = useSelector(((state: any) => state.Parcours ));
  const agence = String(data.office);
  const dispatch = useDispatch();

  window.setTimeout((): void => {
    dispatch({ type: 'RESET ' });
    history.push(`/?agence=${agence}`);
  },300000);
}
