import React, { FC, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { addTimeout } from 'utils/function/addTimeout';
import AlfredDataInterface from 'types/alfred.type';
import AlfredDataInterfaceService from '../../services/alfred.service';
import image from '../../assets/img/amiltone_design_blue.png';

const Confirmation: FC = (): JSX.Element => {
  const history = useHistory();
  const dispatch = useDispatch();
  addTimeout();
  // Récupération du store

  const data: AlfredDataInterface = useSelector(((state: any) => state.Parcours ));
  const agence = String(data.office);

  // Gestion d'ajout visiteur

  useEffect(() => {
    createVisitor();
  }, []);

  const createVisitor = (): void => {
    AlfredDataInterfaceService.create(data)
      .catch(((e: any) => {
        console.log(e);
        history.push('/404');
      }));
  };

  return (
    <>
      <div className="amiltone-design-custom-size">
        <amds-image className="float-left" src={image} shape="square" size="full" type="Default"/>
      </div>
      <div className="alfw-confirmation-body">
        <amds-text font="headline-h3" color="third-600">
          Merci {data.first_name} !
        </amds-text>
        <amds-text font="basic-black" color="basic-black">
          <FormattedMessage id="confirmation.confirmationMessage" />
        </amds-text>
        <amds-text font="body-body3" color="primary-700">
          <FormattedMessage id="confirmation.meetingMessage" />
        </amds-text>
        <amds-button
          size = "medium"
          shape = "filled"
          color = "primary-500"
          elevation = "elevation0"
          onClick = {
            (): any => {
              dispatch({ type: 'RESET' });
              history.push(`/?agence=${agence}`);
            }
          }>
          <amds-text font="button-large" color="basic-white">
            <FormattedMessage id="confirmation.backToMenuMessage" />
          </amds-text>
        </amds-button>
        <Link target="_blank"
          to={{
            pathname: 'https://www.amiltone.com'
          }}
        >
          <amds-button size="medium" shape="outline" color="primary-500" elevation="elevation0">
            <amds-icon name="ri-share-box-line" size="24" color="primary-500"/>
            <amds-text font="button-large" color="primary-500">
              <FormattedMessage id="confirmation.visitWebsiteMessage" />
            </amds-text>
          </amds-button>
        </Link>
      </div>
    </>
  );
};

export default Confirmation;
