import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { RootReducerType } from 'stores';
import { ThemeProvider } from 'styled-components';
import darkColorTheme from 'assets/styles/_darkColorTheme.module.scss';
import lightColorTheme from 'assets/styles/_lightColorTheme.module.scss';
import projectVariables from 'assets/styles/_projectVariables.module.scss';

/** *************> Theme.tsx
 * Theme display components
 */

// *> Lazyloading of Themes
const LightTheme = React.lazy(() => import('./LightTheme'));
const DarkTheme = React.lazy(() => import('./DarkTheme'));

const darkThemeJs = {
  colors: darkColorTheme,
  transition: projectVariables,
};

const lightThemeJs = {
  colors: lightColorTheme,
  transition: projectVariables,
};

interface ThemeProps {
  children: React.ReactNode;
}

export type ThemeType = typeof darkThemeJs | typeof lightThemeJs;

/**
 * *> Theme component
 * Theme High Order Component
 * Change the CSS of the App depending on the Theme enabled in the Store
 */
const Theme: FC<ThemeProps> = ({ children }: ThemeProps): JSX.Element => {
  const themeDark = useSelector(({ Default }: RootReducerType): boolean => Default.themeDark);
  return (
    <ThemeProvider theme={themeDark ? lightThemeJs :  darkThemeJs}>
      <React.Suspense fallback={<></>}>
        { !themeDark && <DarkTheme /> }
        { themeDark && <LightTheme /> }
      </React.Suspense>
      {children}
    </ThemeProvider>
  );
};

export default Theme;
