import storeService from 'services/store.service';
import { addAlert } from 'stores/default';
import ErrorInterface from './error.interface';

/** *************> errors.helper.ts
 * Helper Methods for Errors
 */

const devMode = process.env.NODE_ENV === 'development';

interface HandleErrorOptionsInterface {
  errorConsole?: boolean;
  errorStore?: boolean;
}

/**
 * *> handleErrors()
 * Common method to handle an error into the App
 * By default, when an error occurs, the method logs into the browser Console in development env
 * and adds Alert into the Redux Store
 *
 * @param error : ErrorInterface. The error instance to handle
 * @param options : HandleErrorOptionsInterface. Array of options
 * - errorConsole : Boolean. Define if the error must be logged into the browser Console
 * - errorStore : Boolean. Define if the error must be added to the Redux Store
 */
export const handleErrors = (error: ErrorInterface, options: HandleErrorOptionsInterface = {}): void => {
  const errorConsole = typeof options.errorConsole !== 'undefined' ? options.errorConsole : devMode;
  const errorStore = typeof options.errorStore !== 'undefined' ? options.errorStore : true;

  if(errorConsole) {
    // tslint:disable-next-line: no-console
    console.error(error);
  }

  if(errorStore) {
    storeService().get().dispatch(addAlert(error.key));
  }
};
