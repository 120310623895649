import Theme from 'hoc/Theme';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import Router from 'Router';
import Intl from 'hoc/Intl';
import * as workerService from 'services/worker.service';
import { runServices, ServicesInterface } from 'services/serviceRunner';
import './register-web-components';

/** *************> index.tsx
 * Main file for rendering the React App
 */

// *> Service Runner
// Run and return all services needed for the App
// See services/serviceRunner
const services: ServicesInterface = runServices();

// *> Global context
// Pass all services into the Global React Context
export const GlobalsContext = React.createContext(services);

// *> Render React App method
const renderReactApp = (): void => {
  ReactDOM.render(
    <React.StrictMode>
      <GlobalsContext.Provider value={services}>
        <Provider store={services.store.get()}>
          <stencil-asset/>
          <Intl>
            <Theme>
              <Router/>
            </Theme>
          </Intl>
        </Provider>
      </GlobalsContext.Provider>
    </React.StrictMode>,
    document.getElementById('root'),
  );
};

// *> Render React App call
void (async (): Promise<void> => {
  // *> Keycloak Auth
  // If keycloak is enabled, check if the user is already authenticated
  if(services.keycloak) {
    await services.keycloak.open({ onLoad: 'check-sso', checkLoginIframe: false });
  }
  renderReactApp();
})();

// *> PWA Service Worker
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
workerService.unregister();
