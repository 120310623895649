import React, { FC } from 'react';
import HeaderForm from 'components/globals/structures/HeaderForm';

/** ********************* EXAMPLE CODE ********************* **/

/** *************> HomeLayout.tsx
 * Home Layout Components
 *
 * Layout component are used as a wrapper for the page,
 * Specific page layout can be set into src/routes.config.tsx
 */

// *> Home Layout Props Interface
export interface HomeLayoutProps {
  children: React.ReactNode;
}

/**
 * *> HomeLayout component
 * Example HomePage layout
 */
const HomeLayout: FC<HomeLayoutProps> = ({ children }: HomeLayoutProps): JSX.Element => (
  <>
    <header>
      <HeaderForm />
    </header>
    <div className="main">
      { children }
    </div>
  </>
);
export default HomeLayout;
