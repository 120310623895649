import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

/** *************> axios.service.ts
 * Service Methods for Axios
 *
 * HOW TO USE :
 * - Calling the service :
 * import axiosService, { AxiosServiceInterface } from 'services/axios/axios.service';
 * const service : AxiosServiceInterface = axiosService();
 *
 * - Methods :
 * -- service.getAxiosInstance() : Return one Axios instance by name
 * -- service.init() : Initialize one Axios instance and interceptor
 */

// *> Axios Service Interface
export interface AxiosServiceInterface {
  getAxiosInstance: (instanceName: string) => AxiosInstance;
  init: (
    instanceName: string,
    interceptor: (instance: AxiosInstance, ...arg: any[]) => void ,
    config: AxiosRequestConfig,
    argument?: any[]
  ) => void;
}

const axiosInstance: { [key: string]: AxiosInstance } = {};

/**
 * *> createInstance()
 * Create a new Axios instance with a specific interceptor
 *
 * @param interceptor : Function Interceptor
 * @param config : AxiosRequestConfig
 * @param argument : Array. Arguments called by Function Interceptor
 */
const createInstance = (
  interceptor:  (instance: AxiosInstance, ...arg: any[]) => void,
  config: AxiosRequestConfig,
  argument: any[]
): AxiosInstance => {

  const currentInstance = axios.create(config);
  interceptor(currentInstance, ...argument);
  return currentInstance;
};

/**
 * *> init()
 * Initialize one Axios instance
 *
 * @param instanceName : string
 * @param interceptor : Function Interceptor
 * @param config : AxiosRequestConfig
 * @param argument : Array. Arguments called by Function Interceptor
 */
const init = (
  instanceName: string,
  interceptor: (instance: AxiosInstance, ...arg: any[]) => void ,
  config: AxiosRequestConfig,
  argument?: any[]
): void => {
  axiosInstance[instanceName] = createInstance( interceptor, config, argument ? argument : [] );
};

/**
 * *> getAxiosInstance()
 * Get one Axios instance by name
 *
 * @param instanceName : string
 * @returns AxiosInstance
 */
const getAxiosInstance = (instanceName: string): AxiosInstance => axiosInstance[instanceName];

export default (): AxiosServiceInterface => {
  return { getAxiosInstance, init };
};
