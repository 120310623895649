import en from 'assets/lang/en.json';
import fr from 'assets/lang/fr.json';
import flat from 'flat';
import React, { FC } from 'react';
import { IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';
import { RootReducerType } from 'stores';

/** *************> Intl.tsx
 * I18n components
 */

// *> Available language
const lang = {
  fr,
  en,
};

export interface LangType {
  [key: string]: typeof lang.fr | typeof lang.en;
}

export interface IntlProps {
  children: React.ReactNode;
}

/**
 * *> Intl component
 * I18n High Order Component
 * Include react-intl provider to translate FormattedMessage component
 */
const Intl: FC<IntlProps> = ({ children }: IntlProps): JSX.Element => {
  const language = useSelector(({ Default }: RootReducerType): string => Default.language);
  return (
    <IntlProvider
      locale={language}
      messages={flat((lang as LangType)[language])}
    >
      { children}
    </IntlProvider>
  );
};

export default Intl;
