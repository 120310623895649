import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import AlfredDataInterface from 'types/alfred.type';

// Define a type for the slice state

// Define the initial state using that type
const initialState = {
  date_created: '',
  office: null,
  last_name: '',
  first_name: '',
  company_name: '',
  visited_person: null,
  other_visited_person: '',
  visit_reason: null,
  other_visit_reason: ''
};

export const parcoursSlice = createSlice({
  name: 'parcours',
  initialState,
  reducers: {
    setDateCreated: {
      reducer: (state: AlfredDataInterface, action: PayloadAction<string>): void => {
        state.date_created = action.payload;
      },
      prepare: (date_created: string): { payload: string } => {
        return { payload : date_created };
      }
    },
    setOffice: {
      reducer: (state: AlfredDataInterface, action: PayloadAction<number | null>): void => {
        state.office = action.payload;
      },
      prepare: (office: number): { payload: number } => {
        return { payload : office };
      }
    },
    setFirstName: {
      reducer: (state: AlfredDataInterface, action: PayloadAction<string>): void => {
        state.first_name = action.payload;
      },
      prepare: (first_name: string): { payload: string } => {
        return { payload : first_name };
      }
    },
    setLastName: {
      reducer: (state: AlfredDataInterface, action: PayloadAction<string>): void => {
        state.last_name = action.payload;
      },
      prepare: (last_name: string): { payload: string } => {
        return { payload : last_name };
      }
    },
    setCompanyName: {
      reducer: (state: AlfredDataInterface, action: PayloadAction<string>): void => {
        state.company_name = action.payload;
      },
      prepare: (company_name: string): { payload: string } => {
        return { payload : company_name };
      }
    },
    setVisitedPerson: {
      reducer: (state: AlfredDataInterface, action: PayloadAction<number | null>): void => {
        state.visited_person = action.payload;
      },
      prepare: (visited_person: number | null): { payload: number | null } => {
        return { payload : visited_person };
      }
    },
    setOtherVisitedPerson: {
      reducer: (state: AlfredDataInterface, action: PayloadAction<string>): void => {
        state.other_visited_person = action.payload;
      },
      prepare: (other_visited_person: string): { payload: string } => {
        return { payload : other_visited_person };
      }
    },
    setVisitReason: {
      reducer: (state: AlfredDataInterface, action: PayloadAction<number | null>): void => {
        state.visit_reason = action.payload;
      },
      prepare: (visit_reason: number): { payload: number } => {
        return { payload : visit_reason };
      }
    },
    setOtherVisitReason: {
      reducer: (state: AlfredDataInterface, action: PayloadAction<string>): void => {
        state.other_visit_reason = action.payload;
      },
      prepare: (other_visit_reason: string): { payload: string } => {
        return { payload : other_visit_reason };
      }
    }
  },
  extraReducers: (builder: any) => {
    builder.addCase('RESET', () => {
      return initialState;
    });
  },
});

export const {
  setDateCreated,
  setOffice,
  setFirstName,
  setLastName,
  setCompanyName,
  setVisitedPerson,
  setOtherVisitedPerson,
  setVisitReason,
  setOtherVisitReason
} = parcoursSlice.actions;

export default parcoursSlice.reducer;
