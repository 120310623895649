import ErrorInterface from './error.interface';

/** *************> httpResponseError.ts
 * Error class to throw when an HTTP error occurs
 */
export default class HttpResponseError extends Error implements ErrorInterface {
  // Error Name
  public name: string;
  // Error unique Key
  public key: string;
  // HTTP Status
  public status: number;
  // Error extra data
  public data?: Record<string, unknown>;
  // HTTP Headers
  public headers?: Record<string, unknown>;

  public constructor(
    name: string,
    key: string,
    message: string,
    status: number,
    data: Record<string, unknown>|null = null,
    headers: Record<string, unknown>|null = null
  ){
    super(message);
    this.name = name;
    this.key = key;
    this.status = status;
    if(data) {
      this.data = data;
    }
    if(headers) {
      this.headers = headers;
    }
  }
}
