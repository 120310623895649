import React, { FC } from 'react';
import HeaderForm from 'components/globals/structures/HeaderForm';
import AlertCenter from 'components/globals/structures/AlertCenter';
import { Container } from 'components/bootstrap';

/** *************> DefaultLayout.tsx
 * Default Layout Components
 * This layout is the default App Layout
 *
 * Layout component are used as a wrapper for the page,
 * Specific page layout can be set into src/routes.config.tsx
 */

// *> Default Layout Props Interface
export interface DefaultLayoutProps {
  children: React.ReactNode;
}

/**
 * *> DefaultLayout component
 * Default App Layout
 */
const DefaultLayout: FC<DefaultLayoutProps> = ({ children }: DefaultLayoutProps): JSX.Element => (
  <>
    <header>
      <HeaderForm />
    </header>
    <Container >
      { children }
    </Container>
    <AlertCenter />
  </>
);
export default DefaultLayout;
