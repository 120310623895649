import React, { FC, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setValue } from 'stores/loader';
import { addTimeout } from 'utils/function/addTimeout';
import pink_bottom from '../../assets/img/amiltone_design_pink_bottom.png';

// initialisation du parcours en une seule page.

export const Parcours: FC = (): JSX.Element => {
  const history = useHistory();
  const dispatch = useDispatch();
  addTimeout();
  useEffect(() => {
    dispatch(setValue(20));
  }, []);
  return (
    <>
      <div className="container pt-3">
        <amds-text font="headline-h4" color="primary-600">Qui êtes-vous ?</amds-text>
        <div className="amds-card-custom" onClick={
          (): any => {
            history.push('/particulier');
          }
        }>
          <div className="card-icon" color="primary-100">
            <amds-icon name="person" size="16" color="primary-500"/>
          </div>
          <amds-text font="subtitle-subtitle1" color="primary-500">Je suis un particulier</amds-text>
        </div>
        <div className="amds-card-custom" onClick={
          (): any => {
            history.push('/entreprise');
          }
        }>
          <div className="card-icon" color="primary-100">
            <amds-icon name="ri-briefcase-2-line" size="16" color="primary-500"/>
          </div>
          <amds-text font="subtitle-subtitle1" color="primary-500">Je représente une entreprise</amds-text>
        </div>
      </div>
      <div className="amds-image-fixed-bottom">
        <amds-image src={pink_bottom} shape="square" size="full" type="Default"/>
      </div>
    </>
  );
};
