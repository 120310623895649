import React, { FC } from 'react';
import logo from '../../assets/img/logo-amiltone.png';


/** *************> AmiltoneErrorLayout.tsx
 * Amiltone Layout Components
 *
 * Layout component are used as a wrapper for the page,
 * Specific page layout can be set into src/routes.config.tsx
 */

// *> Amiltone Error Layout Props Interface
export interface AmiltoneErrorLayoutProps {
  children: React.ReactNode;
}

/**
 * *> AmiltoneErrorLayout component
 * Simple render layout
 */
const AmiltoneErrorLayout: FC<AmiltoneErrorLayoutProps> = ({ children }: AmiltoneErrorLayoutProps): JSX.Element => (
  <div className="amiltoneErrorLayout">
    <div className="d-flex justify-content-center">
      <div className="amiltone-logo-custom-size">
        <amds-image
          className = "d-flex flex-column align-items-center"
          src = { logo }
          shape = "square"
          size = "full"
          type = "Default"
        />
      </div>
    </div>
    { children }
  </div>
);
export default AmiltoneErrorLayout;
