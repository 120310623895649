import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/img/logo-amiltone.png';

/** *************> AmiltoneLayout.tsx
 * Amiltone Layout Components
 *
 * Layout component are used as a wrapper for the page,
 * Specific page layout can be set into src/routes.config.tsx
 */

// *> Amiltone Layout Props Interface
export interface AmiltoneLayoutProps {
  children: React.ReactNode;
}

/**
 * *> AmiltoneLayout component
 * Simple render layout
 */
const AmiltoneLayout: FC<AmiltoneLayoutProps> = ({ children }: AmiltoneLayoutProps): JSX.Element => (
  <div className="amiltoneLayout">
    <div className="d-flex justify-content-center">
      <div className="amiltone-logo-custom-size">
        <Link target="_blank" to={{
          pathname: 'https://www.amiltone.com'
        }}>
          <amds-image
            className = "d-flex flex-column align-items-center"
            src = {logo}
            shape = "square"
            size = "full"
            type = "Default"
          />
        </Link>
      </div>
    </div>
    { children }
  </div>
);
export default AmiltoneLayout;
