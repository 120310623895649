import { Store } from 'redux';
import Cookies, { CookieSetOptions } from 'universal-cookie';
import { choiceLanguage, toggleTheme } from '../../stores/default';

/** *************> cookies.config.ts
 * Configuration data for each cookie
 * Available parameters :
 * - name : String. Name of the cookie
 * - options : CookieSetOptions. Options for the cookie (See https://www.npmjs.com/package/universal-cookie)
 * - updateStore : Function. Method called when the store is loaded to update the Store from cookies
 * - updateCookie : Function. Method for updating the cookie
 */

// *> Cookie Keys
export enum CookieKey {
  THEME = 'THEME',
  LANG = 'LANG'
}

// *> Cookie Configuration Interfaces
export interface CookieConfigInterface {
  name: string;
  options?: CookieSetOptions;
  updateStore?: (storeService: Store, value: unknown) => void;
  updateCookie?: (cookie: Cookies, value: unknown) => void;
}

export type CookiesConfigInterface = {
  [key in CookieKey]: CookieConfigInterface;
};

// *> Cookie Configuration
export const cookiesConfig: CookiesConfigInterface = {
  // *> Cookie for Theme preference
  'THEME' : {
    name: '_theme',
    updateStore: (storeService: Store, value: unknown): void => {
      if(value === 'light') {
        storeService.dispatch(toggleTheme());
      }
    }
  },
  // *> Cookie for Language preference
  'LANG' : {
    name: '_lang',
    updateStore: (storeService: Store, value: unknown): void => {
      if(value) {
        storeService.dispatch(choiceLanguage(value as string));
      }
    },
  }


};
